import { graphql } from "gatsby";
import React, { useState } from "react";

import Footer from "../components/footer";
import HeadContent from "../components/head";
import Header from "../components/header";
import Newsletter from "../components/newsletter";
import ResourceList from "../components/resource-list";
import ResourceFilter from "../components/resource-filter";

const ResourcesPage = ({ data }) => {
  const [active, setActive] = useState(-1);

  return (
    <>
      <Header />
      <div className="mx-auto max-w-7xl py-10 px-4 sm:px-6 lg:px-8">
        <div className="bg-white">
          <div className="max-w-xl mb-8">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
              Tailwind Resources
            </h2>
            <p className="mt-5 text-xl text-gray-500">
              Browse a collection of curated resources to help design and build
              your next project with Tailwind CSS
            </p>
          </div>
        </div>

        <div className="mb-4">
          <ResourceFilter active={active} setActive={setActive} />
        </div>

        <ResourceList active={active} resources={data?.resources?.edges} />
      </div>
      <Newsletter />
      <Footer />
    </>
  );
};

export default ResourcesPage;

export const Head = ({ location }) => (
  <HeadContent title="Resources - Using Tailwind" location={location} />
);

export const pageQuery = graphql`
  query AllResourcesQuery {
    resources: allSanityResource {
      edges {
        node {
          id: _id
          name
          url
          description
          type
        }
      }
    }
  }
`;
