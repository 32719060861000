import {
  ChevronRightIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/20/solid";
import React from "react";

const typeMap = {
  1: () => (
    <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 ml-2 text-xs font-medium text-green-800">
      Free
    </span>
  ),
  2: () => (
    <span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 ml-2 text-xs font-medium text-blue-800">
      Free & Paid
    </span>
  ),
  3: () => (
    <span className="inline-flex items-center rounded-full bg-purple-100 px-2.5 py-0.5 ml-2 text-xs font-medium text-purple-800">
      Paid
    </span>
  ),
};

const Resources = ({ active, resources }) => {
  return (
    <div className="overflow-hidden bg-white border-gray-200 border rounded-lg">
      <ul role="list" className="divide-y divide-gray-200">
        {resources.map(({ node: resource }) => {
          if (active !== -1 && active !== resource.type) return null;

          return (
            <li key={resource.id}>
              <a
                href={resource.url}
                className="block hover:bg-gray-50"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <div className="truncate">
                      <div className="flex text-sm">
                        <p className="truncate font-medium text-indigo-600">
                          {resource.name} {typeMap[resource.type]()}
                        </p>
                      </div>
                      <div className="mt-2 flex">
                        <div className="flex items-center text-sm text-gray-500">
                          <p>{resource.description}</p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                      <div className="flex -space-x-1 overflow-hidden text-gray-500 text-sm">
                        <ArrowTopRightOnSquareIcon className="w-5 h-5 mr-1" />{" "}
                        View Resource
                      </div>
                    </div>
                  </div>
                  <div className="ml-5 flex-shrink-0">
                    <ChevronRightIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Resources;
