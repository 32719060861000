import React from "react";

const styles = {
  active:
    "border-indigo-600 bg-indigo-600 px-4 py-2 text-sm font-medium text-white focus:outline-none focus:none",
  inactive:
    "border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:none",
};

const ResourceFilter = ({ active, setActive }) => {
  return (
    <span className="isolate inline-flex rounded-lg">
      <button
        type="button"
        className={`relative inline-flex items-center rounded-l-md border ${
          active === -1 ? styles.active : styles.inactive
        }`}
        onClick={() => setActive(-1)}
      >
        All Resources
      </button>
      <button
        type="button"
        className={`relative -ml-px inline-flex items-center border ${
          active === 1 ? styles.active : styles.inactive
        }`}
        onClick={() => setActive(1)}
      >
        Free
      </button>
      <button
        type="button"
        className={`relative -ml-px inline-flex items-center border ${
          active === 2 ? styles.active : styles.inactive
        }`}
        onClick={() => setActive(2)}
      >
        Free & Paid
      </button>
      <button
        type="button"
        className={`relative -ml-px inline-flex items-center rounded-r-md border ${
          active === 3 ? styles.active : styles.inactive
        }`}
        onClick={() => setActive(3)}
      >
        Paid
      </button>
    </span>
  );
};

export default ResourceFilter;
